import React from "react"
import { BlogPageStyled } from "../styles/blogPageStyle"
import * as moment from "moment"
const BlogHeader = props => {
  const { blogTitle, blogSubtitle, publishedDate, image, publication } = props
  return (
    <BlogPageStyled>
        <div className="kuda-entry--inner flex align-center flex-column top--spacing--md">
          <div className="flex flex-column blog-kuda-section--max mx-auto">
            <div className="flex flex-row">
              <div className="blog-details-date color-secondary">
                {publication}
              </div>

              <div className="blog-details-date color-primary px-2">
                {` | ${moment(publishedDate).format("DD MMM yyyy")}`}
              </div>
            </div>
            <h1 className="blog-heading-title color-primary text-left header--bottom">
              {blogTitle}
            </h1>
            {/* <h2 className="color-black text-left text-font">{blogSubtitle}</h2> */}
            <p className="color-black text-left text-font">{blogSubtitle}</p>
          </div>
          {image?.url &&
          
          <div className="flex justify-center mx-auto blog-header-image--max bottom--spacing blog-header-image--top">
            <div className="blog-image">
              <div className="flex flex-column">
                <img
                  src={`${process.env.GATSBY_BLOGCMS_URL}${image?.url}`}
                  alt="kuda Press Mentions"
                />
                {image?.caption && (
                  <div className="flex flex-row color-default mx-auto text-center p-1 p-md-4">
                    {`Image caption: ${image?.caption}`}
                  </div>
                )}
              </div>
            </div>
          </div>
          
          }
          
        </div>  
    </BlogPageStyled>
  )
}

export default BlogHeader
